import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const useViafoura = (id) => {
  const viafouraId = 'viafouraMainScript';
  const source = '//cdn.viafoura.net/vf-v2.js';
  const [viaFouraReady, setViafouraReady] = useState(false);
  const router = useRouter();

  useEffect(() => {
    function reset() {
      setViafouraReady(true);
      window.vfQ = window.vfQ || [];
      window.vfQ.push(function () {
        window.vf.context.reset();
      });
    }

    function createMetaTag(property, content, callback) {
      const mtag = document.querySelector('meta[property="vf:container_id"]');
      if (!mtag) {
        const metaTag = document.createElement('meta');
        metaTag.setAttribute('property', property);
        metaTag.setAttribute('content', content);
        document.head.appendChild(metaTag);
      }
      callback();
    }

    function loadScript(id, src, callback) {
      const existingScript = document.getElementById(id);

      if (!existingScript) {
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
          if (callback) callback();
        };
      }

      if (existingScript && callback) callback();
    }

    createMetaTag('vf:container_id', `vfid-${id}`, () => {
      if (!viaFouraReady) {
        loadScript(viafouraId, source, reset);
      } else {
        reset();
      }
    });

    router.events.on('routeChangeEnd', reset);

    return () => {
      reset();
      const node = document.querySelector('meta[property="vf:container_id"]');
      if (node) node.parentNode.removeChild(node);
    };
  }, [id]);

  return { viaFouraReady };
};

export default useViafoura;
