import React from 'react';
import { useRouter } from 'next/router';
import { Link } from '@apmg/titan';
import PropTypes from 'prop-types';
import { linkByTypeAs } from '../../utils/cjsutils';

const RelatedLinks = ({ items }) => {
  const router = useRouter();

  let relatedLinks = items
    .filter((item) => linkByTypeAs(item) !== router?.asPath)
    .sort((a, b) => {
      if (a.publishDate > b.publishDate) return -1;
      return 1;
    })
    .slice(0, 4);

  if (relatedLinks.length === 0) {
    return null;
  }

  return (
    <ul className="apm-related-list-body">
      {relatedLinks.map((link) => {
        const href = linkByTypeAs(link);

        return href ? (
          <li className="related_item" key={link.canonicalSlug}>
            <Link href={href} className="related_link">
              {link.title}
            </Link>
          </li>
        ) : null;
      })}
    </ul>
  );
};

RelatedLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      resourceType: PropTypes.string,
      canonicalSlug: PropTypes.string,
    })
  ),
};

export default RelatedLinks;
