import React from 'react';
import PropTypes from 'prop-types';

const ViafouraLivestory = ({ viaFouraReady }) => {
  if (viaFouraReady) {
    return (
      <>
        <div className="viafoura" style={{ margin: '1em 0 0' }}>
          <vf-livestory></vf-livestory>
          <div></div>
          <vf-tray-trigger floating="true"></vf-tray-trigger>
        </div>
      </>
    );
  }

  return null;
};

ViafouraLivestory.propTypes = {
  viaFouraReady: PropTypes.bool,
};

export default ViafouraLivestory;
