export function insertDonateNode(nodeData) {
  if (!nodeData?.content) {
    return nodeData;
  }

  let i = 0;
  let paragraphs = { count: 0, positions: [] };
  let donateNode;

  for (const node of nodeData.content) {
    if (node.type === 'paragraph') {
      paragraphs.count++;
      paragraphs.positions.push(i);
      if (paragraphs.count >= 10) {
        break;
      }
    }
    i++;
  }

  if (paragraphs.count >= 8) {
    // insert after paragraph 6
    donateNode = { type: "donate_ask", attrs: { size: "half" } };
    nodeData.content.splice(paragraphs.positions[3] + 1, 0, donateNode)
  } else {
    // insert at end
    donateNode = { type: "donate_ask" };
    nodeData.content.push(donateNode);
  }

  return nodeData;
}
