const fallback = {
  url: 'https://www.mprnews.org/mpr-news-940x788.png',
  width: 940,
  height: 788,
};

// if there is  social image and  widescreen  use that
// if not  use the uncropped version of the social image
// If no image at all use the fallback
const fishForSocialMediaImage = (content, socialMediaImage = true) => {
  try {
    if (!socialMediaImage) {
      return fallback;
    }
    let img = content?.primaryVisuals?.social?.aspect_ratios?.widescreen
      ? content?.primaryVisuals?.social?.aspect_ratios?.widescreen
      : content?.primaryVisuals?.social?.aspect_ratios?.uncropped;

    const intermediate_intances = img.instances.filter((image) =>
      image.url.match(/gif|jpe?g|png$/)
    );
    const ret = intermediate_intances.reduce((acc, cur) => {
      return acc.width > cur.width ? acc : cur;
    });
    return ret;
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export { fishForSocialMediaImage };
