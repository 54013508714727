import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@apmg/titan';

const HTMLTextbox = ({ html }) => {
  const [showHTML, setShowHTML] = useState(false);
  function handlePublishClick(event) {
    event.preventDefault();
    setShowHTML(true);
  }

  function handleCloseClick(event) {
    event.preventDefault();
    setShowHTML(false);
  }
  return (
    <div className="html-textbox-container">
      {!showHTML && (
        <Button type="primary" onClick={(e) => handlePublishClick(e)}>
          Republish
        </Button>
      )}
      {showHTML && (
        <>
          <Button type="primary" onClick={(e) => handleCloseClick(e)}>
            Close
          </Button>
          <textarea readOnly value={html}></textarea>
        </>
      )}
    </div>
  );
};

HTMLTextbox.propTypes = {
  html: PropTypes.string,
};

export default HTMLTextbox;
