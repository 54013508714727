import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@apmg/titan';
import { analyzeUrl } from '../../utils/cjsutils';

const LinkOverride = (props) => {
  const { isInternal, as } = analyzeUrl(props.href);
  // routedPages are pages that exist in the pages directory so we need to handle them seperately
  const routedPages = [
    '/allnews',
    '/listen',
    '/newsletters',
    '/search',
    '/traffic',
  ];

  if (isInternal && routedPages.includes(as)) {
    return (
      <Link href={as} className={`apm-link ${props.className}`}>
        {props.inner}
      </Link>
    );
  }
  if (isInternal) {
    return (
      <Link
        title={props.title}
        className={`apm-link ${props.className}`}
        href={as}
      >
        {props.inner}
      </Link>
    );
  }

  return (
    <Link
      title={props.title}
      className={`apm-link ${props.className}`}
      href={props.href}
    >
      {props.inner}
    </Link>
  );
};

LinkOverride.propTypes = {
  inner: PropTypes.object,
  href: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default LinkOverride;
